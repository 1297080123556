<template>
  <div style="margin: 0px auto" class="content" id="UPlusquant">
    <img style="position: absolute; top: 40px;height: 24px;vertical-align: middle" src="../assets/img/robot/phone_back.png"
         @click="goBack()">
    <div class="">
      <h4 v-if="language != 'English'" class="modal-title">《UPlus量化平台用户协议》</h4>
      <h4 v-if="language == 'English'" class="modal-title">UPlus Quantitative Platform User Agreement</h4>
    </div>
    <div class="modal-body">
      <div v-if="language != 'English'">
        <h5 style="margin-bottom: 15px"><strong>尊敬的会员：</strong></h5>
        <p>欢迎使用由UPlus提供的数字货币量化交易软件产品服务（以下简称UPlus或UPlus机器人），本用户服务协议（以下简称“本协议”）由您（用户）与UPlus共同签署。</p>
        <p>欢迎使用由UPlus提供的虚拟货币量化交易软件产品服务（以下简称UPlus服务），本服务条款是您与UPlus之间的服务条款。<b>在您注册成为UPlus服务的客户之前，请仔细阅读以下全部内容（特别是加粗的内容）。未成年人则应在法定监护人陪同下阅读。如您使用UPlus机器人，即表示您自愿接受本服务条款的全部内容。如果您不接受本服务条款，请不要安装、使用、注册或以其他方式使用UPlus服务或产品。</b>以下服务条款请您（以下简称“您”）详细阅读了解：
        </p>
        <h5><strong>服务条款的确认和接纳</strong></h5>
        <p>本服务条款内容包括条款正文、UPlus已经发布的或将来可能发布的各类规则。所有规则为本条款不可分割的组成部分，与条款正文具有同等效力。除另行明确声明外，您使用UPlus机器人的行为都应当受本服务条款约束。</p>
        <h5><strong>UPlus机器人服务简介</strong></h5>
        <p>
          本服务条款所称的UPlus服务是指由UPlus所有和运营的UPlus数字货币量化交易软件服务。UPlus数字货币量化交易软件服务包括众多具体服务项目，UPlus数字货币量化交易服务的具体内容及功能详见UPlus网站及各具体服务项目网页公布的服务项目信息。</p>
        <h5><strong>UPlus帐号注册及使用规则</strong></h5>
        <p>（1）您在注册UPlus帐号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在UPlus帐号注册资料中出现违法和不良信息</p>
        <p>（2）您应对注册的UPlus帐号负责，您保证只有您本人可以使用您的UPlus帐号，该帐号不可转让、不可赠与、不可继承。</p>
        <p>
          （3）您对其注册的UPlus软件及密码进行的一切操作负完全的责任，且您同意对其的帐号和密码进行妥善保管，对于因密码泄露所致的损失，由您自行承担。如您发现有他人冒用或盗用您的帐号及密码或任何其他未经合法授权之情形时，应立即以有效方式通知UPlus公司，要求UPlus公司暂停相关服务。同时，您理解UPlus公司对其的请求采取行动需要合理期限，在UPlus公司采取措施前，UPlus公司对已执行的指令及所导致的您的损失不承担任何责任。</p>
        <p>
          （4）您应遵守UPlus的所有其他规定和程序。您须对其在使用UPlus服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在UPlus公司首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予UPlus公司等额的赔偿。若您违反以上规定，UPlus公司有权作出独立判断立即暂停或终止对您提供部分或全部服务，包括冻结、取消您的帐号的使用权限等措施。</p>
        <p>（5）除经书面许可外，您不得修改、翻译、出版、改编、出租、转许可或以其他方式传播或转让UPlus机器人的软件。也不得逆向工程、反编译或试图以其他方式发现UPlus的任何源代码。</p>
        <p>
          （6）您了解UPlus机器人无法保证其所提供的服务毫无瑕疵，但UPlus承诺不断提升服务质量及服务水平。所以您同意：即使UPlus提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为UPlus违约。您同意和UPlus一同合作解决上述瑕疵问题。</p>
        <h5><strong>UPlus机器人的服务费用</strong></h5>
        <p>（1）UPlus机器人的计费方式及价格等信息，以UPlus具体服务项目网页公布的计费和价格信息为准。</p>
        <p>（2）您登陆UPlus网站后，可以开通、中止或终止服务项目等操作。</p>
        <p>（3）在您付费后方可享受UPlus机器人提供的服务。如您注册后7日内未付款的，本服务条款及与您就服务达成的一切协议无效。</p>
        <p>（4）您如需就付费金额开具发票的，需通过向客服邮箱发送电子邮件的形式提出要求，UPlus公司将在30个工作日内向您寄送发票。发票信息和寄送信息以您提供的信息为准，由于信息不准确造成的后果不由UPlus公司承担。</p>
        <p>
          （5）服务期满后双方达成一致，继续由UPlus机器人向您提供服务的，您应至少在服务期满前7日支付续费款项。如续费时UPlus对产品名称、服务或价格进行了调整的，双方同意按照续约当时有效的新的产品名称、服务和价格由UPlus提供服务。</p>
        <p>（6）UPlus机器人服务会根据不同服务项目的收费模式向您收费。</p>
        <p>其他收费方式的具体服务项目：以该具体服务项目网页公布的消费信息提醒为准。</p>
        <p>（7）您服务费用不足时，UPlus公司有权暂停向您提供UPlus机器人服务，因此造成的任何损失，由您自行承担。</p>
        <p>（8）UPlus保留在您未按照约定支付全部费用之前不向您提供服务，或终止服务的权利；并且保留对您逾期支付行为追究法律责任的权利。
        </p>
        <h5><strong>服务条款的变更和中止</strong></h5>
        <p>
          UPlus机器人服务始终在不断变更和改进服务。UPlus机器人可能会增加或删除部分功能或服务，也可能暂停或彻底停止本产品。如果UPlus机器人或本服务条款的内容发生重大变更，UPlus将提前通过本服务条款约定的方式向您告知重大变更的内容。如您继续使用UPlus机器人服务，则视为您接受上述变更。如您不接受上述变更，您有权继续按照变更前的内容和标准使用变更部分的UPlus机器人服务直至该部分服务对应的有效订单费用耗尽或停止使用UPlus机器人服务。</p>
        <h5><strong>隐私制度</strong></h5>
        <p>（1）尊重您个人隐私是UPlus公司的一项基本政策。所以，UPlus公司不会公开或透露您的注册资料，除非UPlus公司在诚信的基础上认为透露这些信息在以下几种情况是必要的：</p>
        <p>（a）事先获得您的明确授权；</p>
        <p>（b）遵守有关法律规定，包括在国家有关机关查询时，提供您的注册信息；</p>
        <p>（c）保持维护UPlus的知识产权和其他重要权利；</p>
        <p>（d）在紧急情况下竭力维护您个人和社会大众的隐私安全；</p>
        <p>（e）根据本条款相关规定或者UPlus公司认为必要的其他情况下。</p>
        <h5><strong>不可抗力条款</strong></h5>
        <p>
          UPlus公司对不可抗力导致的损失不承担责任。本服务条款所指不可抗力包括：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、光缆等基础设施损坏、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。</p>
        <h5><strong>服务承诺</strong></h5>
        <p>UPlus机器人服务承诺及服务不可用的补偿标准以UPlus机器人服务各具体服务项目网页公布的服务等级协议为准。</p>
        <p>UPlus机器人服务，除涉及第三方授权的内容或技术外，均属于UPlus公司，并受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护。<b>所以，您只能在UPlus公司授权下才能使用这些内容，未经UPlus公司产品书面同意，您不能为任何营利或非营利性的目的擅自复制、再造这些内容、或创造与内容有关的派生产品、或以转让、许可的方式授权第三方实施、利用和转让上述知识产权。</b>
        </p>
        <p> UPlus等本文中提及的产品和服务名称有可能为UPlus公司或其关联公司的商标，未经UPlus公司事先书面同意，您不得以任何方式展示或使用或作其他处理，也不得向他人表明您有权展示、使用、或其他有权处理的行为。</p>
        <h5><strong>信息储存及相关知识产权</strong></h5>
        <p> UPlus机器人由UPlus公司研发。UPlus机器人的专利、版权、设计等知识产权，以及与UPlus相关的所有信息内容，包括但不限于文字、图片、档案、资讯、资料、网站架构、页面设计均由UPlus公司依法享有知识产权。/p>
        <h5><strong>保密</strong></h5>
        <p>
          您和UPlus对于因使用UPlus机器人服务而了解或接触到的对方的商业秘密及其他机密和信息，服务和协议内容及您使用UPlus机器人过程中产生的相关信息及往来文件（以下统称“保密信息”）均应保守秘密；非经书面同意，不得向第三方泄露、给予或转让该保密信息。本保密条款不因本协议的终止而终止。</p>
        <h5><strong>法律</strong></h5>
        <p><b>本条款适用中华人民共和国的法律，并且排除一切冲突法规定的适用。</b></p>
        <p><b>如使用本条款项下服务中出现纠纷的，您与UPlus公司应友好协商解决，协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。若单项条款与本服务条款在管辖约定内容上存在冲突，则在单项条款约束范围内应以单项条款为准。</b>
        </p>
        <h5><strong>其他</strong></h5>
        <p> 本条款自发布之日起实施，并构成您和UPlus公司之间的共识。UPlus公司不行使、未能及时行使或者未充分行使本条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响UPlus公司在将来行使该权利。
          如UPlus机器人服务各具体服务项目网页基于具体服务项目提供服务的情况发布的服务规则与本服务条款不一致的，以具体服务项目的服务规则为准；具体服务项目的规则没有约定的，以本条款为准。</p>
        <p style="color: red;line-height: 20px;margin-top:10px;text-align: center;">
          特别提示：您在本页面点击确认即表示签署确认本风险揭示书，表明您已经理解并愿意自行承担接受自动化策略交易的风险和损失。</p>
      </div>
      <div v-if="language == 'English'">
        <h5 style="margin-bottom: 15px"><strong>Dear Member:</strong></h5>
        <p>Welcome to use the digital currency quantitative trading software products and services provided by UPlus (hereinafter referred to as UPlus or UPlus robot). This user service agreement (hereinafter referred to as "this agreement") is jointly signed by you (the user) and UPlus. </p>
        <p>Welcome to use the virtual currency quantitative trading software products and services provided by UPlus (hereinafter referred to as UPlus services). These terms of service are the terms of service between you and UPlus. <b>Please read all of the following (especially those in bold) carefully before you register as a customer of UPlus's services. Minors should be accompanied by legal guardians to read. If you use the UPlus robot, you voluntarily accept the entire contents of these Terms of Service. IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE, REGISTER OR OTHERWISE USE UPlus Services or Products. </b> Please read the following terms of service (hereinafter referred to as "you") in detail:
        </p>
        <h5><strong>Acknowledgment and Acceptance of Terms of Service</strong></h5>
        <p>The content of this Terms of Service includes the text of the terms and various rules that UPlus has published or may publish in the future. All rules are an integral part of these terms and have the same effect as the text of the terms. Unless otherwise expressly stated, your use of UPlus Robots shall be governed by these Terms of Service. </p>
        <h5><strong>Introduction to UPlus Robotics</strong></h5>
        <p>
          The UPlus service referred to in these terms of service refers to the UPlus digital currency quantitative trading software service owned and operated by UPlus. The UPlus digital currency quantitative trading software service includes many specific service items. For the specific content and functions of the UPlus digital currency quantitative trading service, please refer to the service item information published on the UPlus website and each specific service item webpage. </p>
        <h5><strong>UPlus account registration and usage rules</strong></h5>
        <p>(1) When you register your UPlus account, you promise to abide by the seven bottom lines of laws and regulations, the socialist system, national interests, legitimate rights and interests of citizens, public order, social morality and authenticity of information, and must not appear in the UPlus account registration information Illegal and Bad Information</p>
        <p>(2) You are responsible for the registered UPlus account, and you guarantee that only you can use your UPlus account, which is non-transferable, non-gift, and non-inheritable. </p>
        <p>
          (3) You are fully responsible for all operations of your registered UPlus software and password, and you agree to keep your account and password properly, and you are responsible for the loss caused by the leakage of the password. If you find that someone else has used or stolen your account number and password or any other situation without legal authorization, you should immediately notify UPlus in an effective manner and request UPlus to suspend related services. At the same time, you understand that UPlus needs a reasonable period of time to take action on its request. Before UPlus takes measures, UPlus will not be responsible for the executed instructions and your losses. </p>
        <p>
          (4) You shall comply with all other regulations and procedures of UPlus. You are legally responsible for your actions in the use of UPlus's services. The forms of your legal responsibility include, but are not limited to: compensating the infringed, and after UPlus first assumes the administrative penalty or tort damage caused by your actions, you shall pay UPlus an equal amount of compensation. If you violate the above provisions, UPlus has the right to make an independent judgment to immediately suspend or terminate some or all of the services provided to you, including freezing, canceling the use rights of your account and other measures. </p>
        <p>(5) You may not modify, translate, publish, adapt, rent, sublicense, or otherwise distribute or transfer UPlus Robotics' software, except with written permission. Nor may you reverse engineer, decompile or otherwise attempt to discover any of UPlus's source code. </p>
        <p>
          (6) You understand that UPlus Robot cannot guarantee that the services it provides are flawless, but UPlus promises to continuously improve service quality and service level. Therefore, you agree: even if the services provided by UPlus are defective, the above-mentioned defects are unavoidable at the technical level of the industry at that time, and will not be regarded as a breach of contract by UPlus. You agree to work with UPlus to resolve the above-mentioned defects. </p>
        <h5><strong>Service Fees for UPlus Robots</strong></h5>
        <p>(1) The billing method and price information of UPlus robots shall be subject to the billing and price information published on the website of UPlus's specific service items. </p>
        <p>(2) After you log in to the UPlus website, you can activate, suspend or terminate services and other operations. </p>
        <p>(3) You can enjoy the services provided by UPlus Robot after you pay. If you do not pay within 7 days after registration, these terms of service and all agreements with you regarding the service are invalid. </p>
        <p>(4) If you need to issue an invoice for the payment amount, you need to make a request by sending an email to the customer service mailbox, and UPlus will send you an invoice within 30 working days. Invoice information and delivery information are subject to the information you provide, and UPlus will not be responsible for the consequences caused by inaccurate information. </p>
        <p>
          (5) After the expiration of the service period, both parties have reached an agreement that if UPlus Robot continues to provide you with services, you should pay the renewal fee at least 7 days before the expiration of the service period. If UPlus adjusts the product name, service or price during renewal, both parties agree to provide services by UPlus in accordance with the new product name, service and price that are valid at the time of renewal. </p>
        <p>(6) UPlus Robot Service will charge you according to the charging mode of different service items. </p>
        <p>Specific service items of other charging methods: The consumption information reminder published on the webpage of the specific service item shall prevail. </p>
        <p>(7) When your service fee is insufficient, UPlus company has the right to suspend the provision of UPlus robot service to you, and any loss caused thereby shall be borne by you. </p>
        <p>(8) UPlus reserves the right not to provide you with services or to terminate services before you have paid all the fees as agreed; and reserves the right to pursue legal responsibility for your overdue payment.
        </p>
        <h5><strong>Changes and discontinuations of terms of service</strong></h5>
        <p>
          UPlus Robotic Services is always changing and improving the Services. UPlus Robots may add or delete some features or services, and may suspend or completely stop this product. If there are major changes to the UPlus robot or the terms of service, UPlus will notify you of the major changes in advance through the methods stipulated in these terms of service. If you continue to use the UPlus robot service, you are deemed to have accepted the above changes. If you do not accept the above changes, you have the right to continue to use the changed part of the UPlus robot service according to the content and standards before the change until the valid order fee corresponding to the part of the service is exhausted or stop using the UPlus robot service. </p>
        <h5><strong>Privacy Policy</strong></h5>
        <p>(1) Respecting your personal privacy is a basic policy of UPlus. Therefore, UPlus will not disclose or disclose your registration information unless UPlus deems it necessary to disclose such information on the basis of good faith in the following situations:</p>
        <p>(a) with your express prior authorization;</p>
        <p>(b) Comply with relevant laws and regulations, including providing your registration information when inquiring about relevant state agencies;</p>
        <p>(c) maintain UPlus's intellectual property and other important rights;</p>
        <p>(d) Make every effort to maintain your personal and public privacy in an emergency;</p>
        <p>(e) In accordance with the relevant provisions of these Terms or as otherwise deemed necessary by UPlus. </p>
        <h5><strong>Force Majeure Clause</strong></h5>
        <p>
          UPlus is not responsible for losses caused by force majeure. The force majeure referred to in these terms of service includes: natural disasters, changes in laws and regulations or government orders, and reasons unique to the characteristics of network services, such as failure of domestic and overseas basic telecommunications operators, damage to infrastructure such as optical cables, computer or Internet-related technical defects, Internet coverage limitations, computer viruses, hacker attacks and other factors, and other unforeseeable, unavoidable and insurmountable objective situations within the legal scope. </p>
        <h5><strong>Service Commitment</strong></h5>
        <p>UPlus Robot Service Commitment and the compensation standard for unavailability of services are subject to the service level agreement published on the website of each specific service item of UPlus Robot Service. </p>
        <p>UPlus Robot Service, except for content or technology authorized by a third party, belongs to UPlus Company, and is subject to the Copyright Law, Trademark Law, Patent Law, Anti-Unfair Competition Law of the People's Republic of China and corresponding international treaties and other knowledge Protection of property laws and regulations. <b>Therefore, you can only use these contents under the authorization of UPlus Company. Without the written consent of UPlus Company products, you cannot copy, reproduce, or create content-related contents for any profit or non-profit purpose. Derivatives, or authorizing third parties to implement, utilize and transfer the above intellectual property rights by way of assignment or license. </b>
        </p>
        <p> UPlus and other product and service names mentioned in this article may be trademarks of UPlus Corporation or its affiliates. You may not display or use or otherwise deal with it in any way without the prior written consent of UPlus Corporation, nor to Others indicate that you have the right to display, use, or otherwise have the right to act. </p>
        <h5><strong>Information storage and related intellectual property</strong></h5>
        <p> The UPlus robot was developed by the UPlus Corporation. UPlus Robot's intellectual property rights such as patents, copyrights, designs, and all information related to UPlus, including but not limited to text, pictures, files, information, materials, website architecture, and page design, are legally owned by UPlus Company. /p>
        <h5><strong>Confidential</strong></h5>
        <p>
          You and UPlus have access to each other's trade secrets and other secrets and information that you and UPlus know or come into contact with due to the use of UPlus's services, the content of services and agreements, and the relevant information and exchange documents generated during your use of UPlus's robots (hereinafter collectively referred to as "confidential information"). ) shall be kept confidential; such confidential information shall not be disclosed, given or transferred to third parties without written consent. This Confidentiality Policy does not terminate upon termination of this Agreement. </p>
        <h5><strong>Legal</strong></h5>
        <p><b>These Terms shall be governed by the laws of the People's Republic of China, to the exclusion of all conflict of laws provisions. </b></p>
        <p><b>If there is a dispute in the use of the services under these terms, you and UPlus should negotiate amicably to resolve it. If the negotiation fails, you agree to submit the dispute or dispute to the people's court with jurisdiction in the place where this agreement is signed. If there is a conflict between a single clause and this Terms of Service in terms of jurisdiction, the single clause shall prevail within the scope of the single clause. </b>
        </p>
        <h5><strong>Other</strong></h5>
        <p> These Terms are effective as of the date of publication and constitute an agreement between you and UPlus Corporation. UPlus's non-exercise, failure to exercise in a timely manner, or failure to fully exercise its rights under these Terms or in accordance with the law shall not be deemed a waiver of such rights and shall not affect UPlus's future exercise of such rights.
          If the service rules published on the specific service item webpage of UPlus Robot Service based on the service provided by the specific service item are inconsistent with these terms of service, the service rules of the specific service item shall prevail; if the rules of the specific service item are not stipulated, the terms of this service shall prevail. prevail. </p>
        <p style="color: red;line-height: 20px;margin-top:10px;text-align: center;">
          Special reminder: By clicking confirm on this page, you sign and confirm this risk disclosure letter, indicating that you have understood and are willing to bear the risks and losses of accepting automated strategy transactions. </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserUsageProtocol",

  data(){
    return {
      language: '简体中文'
    }
  },

  mounted() {
    this.language = localStorage.getItem('language')
  },

  methods:{
    goBack() {
      // this.$router.back()
      sessionStorage.setItem('goBack', '1')
      this.$router.go(-1);
      // window.history.back()
    }
  }
}
</script>

<style scoped>

.modal-title {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

.modal-body > h5, p{
  font-size: 14px;
  margin: 0px;
  line-height: 22px;
}
@media screen and (max-width: 900px) {
  .content {
    width: 90%;
    padding: 30px 0px;
    margin: 0 auto;
    position: relative;
    bottom: 20px;
  }
}
@media screen and (min-width: 900px) {
  .content {
    width: 50%;
    padding: 30px;
    margin: 0 auto;
    position: relative;
    bottom: 20px;
  }
}

</style>
